import React from "react";
import logo from "./logo.svg";
import topImage from "./resources/topImage.jpg";

import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={topImage} className="top-image" alt="background" />
        <div className="container">
          <img src={logo} alt="Dependiator" />
          <p className="Title">Dependiator</p>
        </div>
        <p className="Text">Specializes in Azure cloud and .NET development.</p>
        <p className="Text">info@dependiator.com</p>
      </header>
    </div>
  );
}

export default App;
